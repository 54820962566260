import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'

const Home = lazy(() => import("./common/Home"));
const TermsOfService = lazy(() => import("./common/TermsOfService"));
const PrivacyPolicy = lazy(() => import("./common/PrivacyPolicy"));
const Support = lazy(() => import("./common/Support"));
const EmergencyView = lazy(() => import("./emergency/Emergency"));
const NotFound = lazy(() => import("./common/NotFound"));

export default function AppRoutes() {
  return (
    <Suspense fallback={
      <div className="align-self-center">
        <Spinner animation="border" variant="secondary" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    }>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="support" element={<Support />} />
        <Route path="e/:activity_id" element={<EmergencyView />} />
  		<Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}